exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-anmelden-tsx": () => import("./../../../src/pages/anmelden.tsx" /* webpackChunkName: "component---src-pages-anmelden-tsx" */),
  "component---src-pages-bisbald-tsx": () => import("./../../../src/pages/bisbald.tsx" /* webpackChunkName: "component---src-pages-bisbald-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-keine-anmeldung-tsx": () => import("./../../../src/pages/keine-anmeldung.tsx" /* webpackChunkName: "component---src-pages-keine-anmeldung-tsx" */)
}

